// src/utils下创建i18n/index.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'
// 引入各个语言配置文件
import zh from '@/i18n/lang/zh';
import en from '@/i18n/lang/en';
import zhs from "@/i18n/lang/zhs";
import cs from "@/i18n/lang/cs";
import da from "@/i18n/lang/da";
import nl from "@/i18n/lang/nl";
import et from "@/i18n/lang/et";
import fi from "@/i18n/lang/fi";
import fr from "@/i18n/lang/fr";
import el from "@/i18n/lang/el";
import hu from "@/i18n/lang/hu";
import ro from "@/i18n/lang/ro";
import it from "@/i18n/lang/it";
import lv from "@/i18n/lang/lv";
import lt from "@/i18n/lang/lt";
import mt from "@/i18n/lang/mt";
import pl from "@/i18n/lang/pl";
import sk from "@/i18n/lang/sk";
import se from "@/i18n/lang/se";
import pt from "@/i18n/lang/pt";
import de from "@/i18n/lang/de";
import si from "@/i18n/lang/si";
import es from "@/i18n/lang/es";
import id from "@/i18n/lang/id";
import ja from "@/i18n/lang/ja";
import ko from "@/i18n/lang/ko";

Vue.use(VueI18n)
// 创建vue-i18n实例i18n
const i18n = new VueI18n({
  // 设置默认语言
  locale: localStorage.lang || 'en', // 语言标识
  // 添加多语言（每一个语言标示对应一个语言文件）
  messages: {
    zh,
    zhs,
    en,
    cs,
    ja,
    ko,
    da,
    nl,
    et,
    fi,
    fr,
    de,
    el,
    hu,
    ro,
    it,
    lv,
    lt,
    mt,
    pl,
    id,
    sk,
    si,
    es,
    se,
    pt
  }
})
// 暴露i18n
export default i18n
