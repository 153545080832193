// 创建文件en/index.js
const id = {
  route: {
    home: 'Beranda',
    shopCart: 'Keranjang Belanja',
    popularStore: 'Toko Populer',
    storeDetails: 'Detail Toko',
    hotSellingProducts: 'Produk Jual Panas',
    productClassification: 'Klasifikasi Produk',
    productDetails: 'Detail Produk',
    orderPay: 'Pembayaran Pesanan',
  },
  home: "Home",
  wallet: "Wallet",
  order: "Order Center",
  sell: "Sell Center",
  cart: "Cart",
  search: "Search",
  deals: "Today's deals",
  featuredStore: "Featured Store",
  spotlight: "Spotlight",
  hotSale: "Hot Sale",
  fashion: "Fashion",
  beautyPersonalCare: "Beauty & Personal Care",
  homeKitchen: "Home & Kitchen",
  sportsOutdoors: "Sports & Outdoors",
  electronicsAccessories: "Electronics & Accessories",
  seeAll: "See All",
  category: "Category",
  beauty: 'Kecantikan',
  personalCare: 'Perawatan Pribadi',
  homeFurnishing: 'Rumah',
  kitchen: 'Dapur',
  sports: 'Olahraga',
  outdoors: 'Luar ruangan',
  electronics: 'Elektronik',
  accessories: 'Aksesoris',
  office: 'Kantor',
  products: 'Produk',
  food: 'Makanan',
  drinks: 'Minuman',
  collectibles: 'Barang Koleksi',
  automotive: 'Otomotif',
  storeOnTikTokShop: 'Toko di TikTok Shop',
  viewShop: 'Lihat Toko',
  reviews: 'Ulasan',
  topProducts: 'Produk teratas',
  newReleases: 'Rilis baru',
  balance: 'Saldo',
  recharge: 'Isi ulang',
  withdraw: 'Penarikan',
  fundAccount: 'Rekening Dana',
  add: 'Tambah',
  addFundAccount: 'Tambah Akun Dana',
  crypto: 'Kripto',
  mainnet: 'Mainnet',
  address: 'Alamat',
  debitCards: 'Kartu Debit',
  iban: 'IBAN',
  account: 'Rekening',
  cardNumber: 'Nomor Kartu',
  usdt_trc20: 'USDT-TRC20',
  usdt_erc20: 'USDT-ERC20',
  btc: 'BTC',
  eth: 'ETH',
  hsbc: 'HSBC',
  ubs: 'UBS',
  bnp: 'BNP',
  amount: 'Jumlah',
  qrCode: 'Kode QR',
  rechargeAmountUsdt: 'Jumlah isi ulang (USDT)',
  pleaseEnterTheRechargeAmountUsdt: 'Masukkan jumlah isi ulang (USDT)',
  submitForReview: 'Kirim untuk ditinjau',
  purchased: "Purchased",
  carriage: "Carriage",
  completed: "Completed",
  awaitingShipment: 'Menunggu Pengiriman',
  orderDetails: "Order Details",
  receive: "Receive",
  review: 'Tinjau',
  enterprise: 'Perusahaan',
  personal: 'Pribadi',
  businessName: 'Nama Bisnis',
  headOfEnterprise: 'Kepala Perusahaan',
  headPhoneNumber: 'Nomor Telepon Kepala',
  headWhatsAppNumber: 'Nomor WhatsApp Kepala',
  submit: 'Kirim',
  uploadLogo: 'Unggah Logo',
  storeName: 'Nama Toko',
  storeEmail: 'Email Toko',
  storeHeadName: 'Nama Kepala Toko',
  storeHeadIdNumber: 'Nomor ID Kepala Toko',
  idFrontPhoto: 'Foto Depan ID',
  idBackPhoto: 'Foto Belakang ID',
  personalHandheldIdPhoto: 'Foto ID genggam pribadi',
  storeHeadPhoneNumber: 'Nomor Telepon Kepala Toko',
  storeSupportNumber: 'Nomor Dukungan Toko',
  invitationCode: 'Kode Undangan',
  login: 'Masuk',
  createAccount: 'Buat akun',
  emailOrPhone: 'Email atau nomor ponsel',
  password: 'Kata sandi',
  signIn: 'Masuk',
  yourName: 'Nama Anda',
  reEnterPassword: 'Masukkan kembali kata sandi',
  emailOrPhoneCode: 'Email atau kode telepon',
  request: 'Permintaan',
  createAccountButton: 'Buat akun',
  privacyPolicy: '《Kebijakan Privasi TikTok》',
  acknowledgmentText: 'Dengan mengklik Simpan, Anda menyatakan bahwa Anda telah membaca {kebijakan privasi}. Informasi Anda dapat digunakan untuk mempersonalisasi pengalaman Anda, termasuk iklan, tergantung pada pengaturan Anda.',
  buy: 'Beli',
  exploreBargains: 'Jelajahi penawaran berkualitas',
  aboutSpotlight: 'Tentang Spotlight',
  freeShipping: 'Gratis ongkos kirim',
  freeShippingDescription: 'Untuk pesanan di atas $25',
  freeReturns: 'Pengembalian gratis',
  freeReturnsDescription: 'Dalam 30 hari',
  shopPerformance: 'Kinerja toko',
  betterThan: 'Lebih baik dari 80% toko lain',
  starRating: 'Peringkat bintang 4+',
  shipsWithin: 'Dikirim dalam waktu 2 hari',
  reviewsCount: '({menghitung} ulasan)',
  cartEmpty: 'Keranjang Anda kosong',
  selectAll: 'Pilih semua',
  totalItems: 'Total ({num} item)',
  checkout: 'Pembayaran',
  shippingReturnsTitle: 'Pengiriman & pengembalian',
  estimatedDelivery: 'Perkiraan pengiriman 11-13 Juli, $7.43',
  shippingTo: 'Pengiriman ke Denver, 17517',
  shipsOut: 'Biasanya dikirim dalam 1 hari kerja',
  returnPolicyTitle: 'Kebijakan pengembalian',
  addToCart: 'Tambahkan ke troli',
  aboutThisProduct: 'Tentang produk ini',
  shippingAddress: 'Alamat pengiriman',
  addAddress: 'Tambahkan alamat',
  paymentMethod: 'Metode pembayaran',
  shippingMethod: 'Metode pengiriman',
  orderSummary: 'Ringkasan pesanan',
  subtotal: 'Subtotal',
  shipping: 'Pengiriman',
  shippingToBeCalculated: 'untuk dihitung',
  total: 'Total ({num} item)',
  firstName: 'Nama depan',
  lastName: 'Nama belakang',
  street: 'Jalan',
  apartment: 'Apartemen, suite, dll. (opsional)',
  city: 'Kota',
  state: 'Negara bagian',
  zipCode: 'Kode pos',
  phone: 'Telepon',
  save: 'Simpan',
  privacyPolicyDescription: 'Dengan mengklik Simpan, Anda menyatakan bahwa Anda telah membaca {kebijakan privasi}. Informasi Anda dapat digunakan untuk mempersonalisasi pengalaman Anda, termasuk iklan, tergantung pada pengaturan Anda.',
  usCountryCode: 'AMERIKA SERIKAT + 1',
  logout: 'Keluar',
  selectShippingMethod: 'Pilih Metode Pengiriman',
  standardShipping: "Standard Shipping: ${amount}",
  estimatedDeliverys: "Estimated Delivery: {date}",
  taxes: "Taxes",
  breadcrumbs: {
    classification: 'Klasifikasi',
    subClassification: 'Subklasifikasi',
    selectedProduct: 'Produk yang Dipilih',
  },
  downloadApp: 'Unduh Aplikasi',
  register: 'Daftar',
  verifiedPurchase: 'Pembelian Terverifikasi',
  pleaseInput: 'Silakan Masukkan ',
  code: 'Kode',
  effective: 'Efektif ',
  pleaseEnterPasswordAgain: 'Silakan Masukkan Kata Sandi Anda Sekali Lagi',
  passwordInconsistent: 'Kata Sandi yang Dimasukkan Dua Kali Tidak Konsisten!',
  createdSuccessfully: 'Berhasil Dibuat',
  addedSuccessfully: 'Berhasil ditambahkan',
  modifyAddress: 'Ubah Alamat',
  modifiedSuccessfully: 'Berhasil dimodifikasi',
  copy: 'Teks Telah Disalin ke Clipboard!',
  rechargeSuccessful: 'Mengisi Ulang Berhasil',
  deleteSuccessfully: 'Berhasil Menghapus',
  paymentSuccessful: 'Pembayaran Berhasil',
  payOrder: 'Bayar Pesanan',
  pay: 'Bayar',
  available: 'Tersedia',
  enterPaymentPassword: 'Masukkan 6 digit Kata Sandi Pembayaran',
  confirmSuccessfulReceiptOfGoods: 'Konfirmasi Penerimaan Barang Berhasil',
  correct: ' Benar ',
  pleaseUploadInFull: 'Silakan Unggah Gambar yang Diperlukan Secara Lengkap!',
  submittedSuccessfully: 'Berhasil Dikirim',
  evaluate: 'Evaluasi',
  PleaseFillInTheFormCompletely: 'Silakan Isi Formulir dengan Lengkap!',
  qrCodePlaceholder: 'Kode QR',
  verificationCodeSentSuccessfully: 'Kode Verifikasi Berhasil Dikirim, Silakan Isi dan Kirim Dalam 60 Detik!',
  pleaseEnterYourEmailFirst: 'Silakan Masukkan Email Anda Terlebih Dahulu',
  ContactTheMerchant: 'Hubungi Penjual',
  inputIssues: 'Masalah Masukan',
}
export default id
