// 创建文件en/index.js
const ko = {
  route: {
    home: '홈',
    shopCart: '쇼핑 카트',
    popularStore: '인기 스토어',
    storeDetails: '스토어 상세 정보',
    hotSellingProducts: '인기 판매 제품',
    productClassification: '제품 분류',
    productDetails: '제품 세부 정보',
    orderPay: '주문 결제',
  },
  home: "Home",
  wallet: "Wallet",
  order: "Order Center",
  sell: "Sell Center",
  cart: "Cart",
  search: "Search",
  deals: "Today's deals",
  featuredStore: "Featured Store",
  spotlight: "Spotlight",
  hotSale: "Hot Sale",
  fashion: "Fashion",
  beautyPersonalCare: "Beauty & Personal Care",
  homeKitchen: "Home & Kitchen",
  sportsOutdoors: "Sports & Outdoors",
  electronicsAccessories: "Electronics & Accessories",
  seeAll: "See All",
  category: "Category",
  beauty: '뷰티',
  personalCare: '퍼스널 케어',
  homeFurnishing: '홈',
  kitchen: '주방',
  sports: '스포츠',
  outdoors: '아웃도어',
  electronics: '전자제품',
  accessories: '액세서리',
  office: '사무실',
  products: '제품',
  food: '식품',
  drinks: '음료',
  collectibles: '수집품',
  automotive: '자동차',
  storeOnTikTokShop: '틱톡 샵 스토어',
  viewShop: '스토어 보기',
  reviews: '리뷰',
  topProducts: '인기 제품',
  newReleases: '신제품 출시',
  balance: 'Balance',
  recharge: '충전',
  withdraw: '출금',
  fundAccount: '펀드 계좌',
  add: '추가',
  addFundAccount: '펀드 계좌 추가',
  crypto: '암호화폐',
  mainnet: '메인넷',
  address: '주소',
  debitCards: '직불 카드',
  iban: 'IBAN',
  account: '계좌',
  cardNumber: '카드 번호',
  usdt_trc20: 'USDT-TRC20',
  usdt_erc20: 'USDT-ERC20',
  btc: 'BTC',
  eth: 'ETH',
  hsbc: 'HSBC',
  ubs: 'UBS',
  bnp: 'BNP',
  amount: '금액',
  qrCode: 'QR 코드',
  rechargeAmountUsdt: '충전 금액(USDT)',
  pleaseEnterTheRechargeAmountUsdt: '충전 금액(USDT)을 입력하세요.',
  submitForReview: '검토를 위해 제출',
  purchased: "Purchased",
  carriage: "Carriage",
  completed: "Completed",
  awaitingShipment: '배송 대기 중',
  orderDetails: "Order Details",
  receive: "Receive",
  review: '검토',
  enterprise: '기업',
  personal: '개인',
  businessName: '비즈니스 이름',
  headOfEnterprise: '기업 책임자',
  headPhoneNumber: '대표 전화 번호',
  headWhatsAppNumber: '대표 WhatsApp 번호',
  submit: '제출',
  uploadLogo: '로고 업로드',
  storeName: '스토어 이름',
  storeEmail: '스토어 이메일',
  storeHeadName: '스토어 대표 이름',
  storeHeadIdNumber: '스토어 대표 ID 번호',
  idFrontPhoto: '신분증 앞면 사진',
  idBackPhoto: '신분증 뒷면 사진',
  personalHandheldIdPhoto: '개인 휴대용 신분증 사진',
  storeHeadPhoneNumber: '스토어 대표 전화번호',
  storeSupportNumber: '스토어 지원 번호',
  invitationCode: '초대 코드',
  login: '로그인',
  createAccount: '계정 만들기',
  emailOrPhone: '이메일 또는 휴대폰 번호',
  password: '비밀번호',
  signIn: '로그인',
  yourName: '이름',
  reEnterPassword: '비밀번호 재입력',
  emailOrPhoneCode: '이메일 또는 휴대폰 번호',
  request: '요청',
  createAccountButton: '계정 만들기',
  privacyPolicy: '틱톡 개인정보 처리방침》',
  acknowledgmentText: '저장을 클릭하면 {개인정보처리방침}을 읽었음을 인정하는 것입니다. 귀하의 정보는 설정에 따라 광고를 포함한 사용자 경험을 맞춤화하는 데 사용될 수 있습니다.',
  buy: '구매하기',
  exploreBargains: '특가 상품 살펴보기',
  aboutSpotlight: 'Spotlight 소개',
  freeShipping: '무료 배송',
  freeShippingDescription: '25달러 이상 주문 시',
  freeReturns: '무료 반품',
  freeReturnsDescription: '30일 이내',
  shopPerformance: '스토어 실적',
  betterThan: '다른 상점의 80%보다 우수',
  starRating: '별점 4개 이상',
  shipsWithin: '2일 이내 배송',
  reviewsCount: '({개수} 리뷰)',
  cartEmpty: '장바구니가 비어 있습니다.',
  selectAll: '모두 선택',
  totalItems: '합계 ({넘버} 항목)',
  checkout: '체크아웃',
  shippingReturnsTitle: '배송 및 반품',
  estimatedDelivery: '예상 배송 7월 11~13일, $7.43',
  shippingTo: '덴버, 17517로 배송',
  shipsOut: '보통 영업일 기준 1일 이내에 배송',
  returnPolicyTitle: '반품 정책',
  addToCart: '장바구니에 담기',
  aboutThisProduct: '이 제품 정보',
  shippingAddress: '배송 주소',
  addAddress: '주소 추가',
  paymentMethod: '결제 방법',
  shippingMethod: '배송 방법',
  orderSummary: '주문 요약',
  subtotal: '소계',
  shipping: '배송',
  shippingToBeCalculated: '계산 대상',
  total: '합계 ({번호} 품목)',
  firstName: '이름',
  lastName: '성',
  street: '거리',
  apartment: '아파트, 스위트 등(선택 사항)',
  city: '도시',
  state: '주',
  zipCode: '우편 번호',
  phone: '전화번호',
  save: '저장',
  privacyPolicyDescription: '저장을 클릭하면 {개인정보처리방침}을 읽었음을 인정하는 것입니다. 귀하의 정보는 설정에 따라 광고를 포함한 사용자 경험을 맞춤화하는 데 사용될 수 있습니다.',
  usCountryCode: 'US+1',
  logout: '로그아웃',
  selectShippingMethod: '배송 방법 선택',
  standardShipping: "Standard Shipping: ${amount}",
  estimatedDeliverys: "Estimated Delivery: {date}",
  taxes: "Taxes",
  breadcrumbs: {
    classification: '분류',
    subClassification: '하위 분류',
    selectedProduct: '선택한 제품',
  },
  downloadApp: '앱 다운로드',
  register: '등록하기',
  verifiedPurchase: '구매 확인',
  pleaseInput: '입력하세요 ',
  code: '코드',
  effective: '유효 ',
  pleaseEnterPasswordAgain: '비밀번호를 다시 입력하세요',
  passwordInconsistent: '두 번 입력한 비밀번호가 일치하지 않습니다!',
  createdSuccessfully: '성공적으로 생성됨',
  addedSuccessfully: '성공적으로 추가됨',
  modifyAddress: '주소 수정',
  modifiedSuccessfully: '수정 성공',
  copy: '텍스트가 클립보드에 복사되었습니다!',
  rechargeSuccessful: '충전 성공',
  deleteSuccessfully: '삭제 성공',
  paymentSuccessful: '결제 성공',
  payOrder: '주문 결제',
  pay: '결제하기',
  available: '사용 가능',
  enterPaymentPassword: '6자리 결제 비밀번호 입력',
  confirmSuccessfulReceiptOfGoods: '상품 수령 성공 확인',
  correct: ' 정답 ',
  pleaseUploadInFull: '필요한 이미지를 모두 업로드하세요!',
  submittedSuccessfully: '성공적으로 제출되었습니다',
  evaluate: '평가하기',
  PleaseFillInTheFormCompletely: '양식을 완전히 작성해 주세요 !',
  qrCodePlaceholder: 'QR 코드',
  verificationCodeSentSuccessfully: '인증 코드가 성공적으로 전송되었습니다, 60초 이내에 입력 후 제출하세요!',
  pleaseEnterYourEmailFirst: '먼저 이메일을 입력하세요',
  ContactTheMerchant: '판매자에게 연락하기',
  inputIssues: '입력 문제',
}
export default ko
