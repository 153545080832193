/**
 * @description 导出网络配置
 **/
const hostUrl = 'https://r7xnegzspfgn.shiniansousou.com/api/'

module.exports = {
  // 默认的接口地址，开发环境和生产环境走/mock-server
  // 当然你也可以选择自己配置成需要的接口地址，如"https://api.xxx.com"
  baseURL: process.env.NODE_ENV === 'production' ? '/api/' : hostUrl,
  // 配后端数据的接收方式application/json;charset=UTF-8 或 application/x-www-form-urlencoded;charset=UTF-8
  contentType: 'application/json;charset=UTF-8',
  // 最长请求时间
  requestTimeout: 100000,
  // 操作正常code，支持String、Array、int多种类型
  successCode: [200, 0, '200', '0'],
  // 数据状态的字段名称
  statusName: 'code',
  // 状态信息的字段名称
  messageName: 'userMsg',
  // 文件上传地址
  uploadUrl: hostUrl + 'utils/upload',
}
