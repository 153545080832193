// 创建文件en/index.js
const ja = {
  route: {
    home: 'ホーム',
    shopCart: 'ショッピングカート',
    popularStore: '人気ストア',
    storeDetails: 'ストア詳細',
    hotSellingProducts: '売れ筋商品',
    productClassification: '製品分類',
    productDetails: '商品詳細',
    orderPay: '注文支払い',
  },
  home: "Home",
  wallet: "Wallet",
  order: "Order Center",
  sell: "Sell Center",
  cart: "Cart",
  search: "Search",
  deals: "Today's deals",
  featuredStore: "Featured Store",
  spotlight: "Spotlight",
  hotSale: "Hot Sale",
  fashion: "Fashion",
  beautyPersonalCare: "Beauty & Personal Care",
  homeKitchen: "Home & Kitchen",
  sportsOutdoors: "Sports & Outdoors",
  electronicsAccessories: "Electronics & Accessories",
  seeAll: "See All",
  category: "Category",
  beauty: 'ビューティー',
  personalCare: 'パーソナルケア',
  homeFurnishing: 'ホーム',
  kitchen: 'キッチン',
  sports: 'スポーツ',
  outdoors: 'アウトドア',
  electronics: 'エレクトロニクス',
  accessories: 'アクセサリー',
  office: 'オフィス',
  products: '製品',
  food: '食品',
  drinks: '飲料',
  collectibles: '収集品',
  automotive: '自動車',
  storeOnTikTokShop: 'TikTokショップ',
  viewShop: 'ショップを見る',
  reviews: 'レビュー',
  topProducts: '人気商品',
  newReleases: 'ニューリリース',
  balance: 'バランス',
  recharge: 'リチャージ',
  withdraw: '引き出し',
  fundAccount: '資金口座',
  add: '追加',
  addFundAccount: 'ファンド口座の追加',
  crypto: '暗号通貨',
  mainnet: 'メインネット',
  address: 'アドレス',
  debitCards: 'デビットカード',
  iban: 'IBAN',
  account: '口座番号',
  cardNumber: 'カード番号',
  usdt_trc20: 'USDT-TRC20',
  usdt_erc20: 'USDT-ERC20',
  btc: 'BTC',
  eth: 'ETH',
  hsbc: 'HSBC',
  ubs: 'UBS',
  bnp: 'BNP',
  amount: '金額',
  qrCode: 'QRコード',
  rechargeAmountUsdt: 'リチャージ金額（USDT）',
  pleaseEnterTheRechargeAmountUsdt: 'リチャージ金額（USDT）を入力してください。',
  submitForReview: '審査のため送信',
  purchased: "Purchased",
  carriage: "Carriage",
  completed: "Completed",
  awaitingShipment: '出荷待ち',
  orderDetails: "Order Details",
  receive: "Receive",
  review: 'レビュー',
  enterprise: '企業',
  personal: '個人',
  businessName: '企業名',
  headOfEnterprise: '企業責任者',
  headPhoneNumber: '代表電話番号',
  headWhatsAppNumber: '代表電話番号',
  submit: '送信',
  uploadLogo: 'ロゴのアップロード',
  storeName: '店舗名',
  storeEmail: '店舗メールアドレス',
  storeHeadName: '店舗責任者名',
  storeHeadIdNumber: '店舗責任者ID番号',
  idFrontPhoto: 'ID正面写真',
  idBackPhoto: 'ID背面写真',
  personalHandheldIdPhoto: '個人携帯ID写真',
  storeHeadPhoneNumber: '店舗責任者電話番号',
  storeSupportNumber: '店舗サポート番号',
  invitationCode: '招待コード',
  login: 'ログイン',
  createAccount: 'アカウント作成',
  emailOrPhone: 'Eメールまたは携帯電話番号',
  password: 'パスワード',
  signIn: 'サインイン',
  yourName: 'お名前',
  reEnterPassword: 'パスワード再入力',
  emailOrPhoneCode: 'Eメールまたは電話番号',
  request: 'リクエスト',
  createAccountButton: 'アカウント作成',
  privacyPolicy: 'TikTokプライバシーポリシー',
  acknowledgmentText: '保存]をクリックすると、｛プライバシーポリシー｝をお読みになったことになります。お客様の情報は、お客様の設定に応じて、広告を含むパーソナライズのために使用される場合があります。',
  buy: '購入する',
  exploreBargains: '掘り出し物を探す',
  aboutSpotlight: 'スポットライトについて',
  freeShipping: '送料無料',
  freeShippingDescription: '25ドル以上のご注文で',
  freeReturns: '返品無料',
  freeReturnsDescription: '30日以内',
  shopPerformance: 'ショップのパフォーマンス',
  betterThan: '他店の80%より優れている',
  starRating: '4つ星以上の評価',
  shipsWithin: '2日以内に発送',
  reviewsCount: '({件数}レビュー)',
  cartEmpty: 'カートが空です',
  selectAll: 'すべて選択',
  totalItems: '合計 ({num} アイテム)',
  checkout: 'チェックアウト',
  shippingReturnsTitle: '配送と返品',
  estimatedDelivery: '配送予定日 7月11日-13日, $7.43',
  shippingTo: '発送元: デンバー, 17517',
  shipsOut: '通常1営業日以内に発送',
  returnPolicyTitle: '返品について',
  addToCart: 'カートに入れる',
  aboutThisProduct: 'この商品について',
  shippingAddress: '配送先住所',
  addAddress: '住所を追加する',
  paymentMethod: 'お支払い方法',
  shippingMethod: '配送方法',
  orderSummary: '注文概要',
  subtotal: '小計',
  shipping: '送料',
  shippingToBeCalculated: '計算されます',
  total: '合計 ({num} アイテム)',
  firstName: '姓',
  lastName: '姓',
  street: 'ストリート',
  apartment: 'アパート、スイートなど（任意）',
  city: '市町村',
  state: '都道府県',
  zipCode: '郵便番号',
  phone: '電話番号',
  save: '保存',
  privacyPolicyDescription: '保存]をクリックすると、{プライバシーポリシー}を読んだことを認めます。あなたの情報は、あなたの設定に応じて、広告を含むあなたの経験をパーソナライズするために使用される場合があります。',
  usCountryCode: 'US+1',
  logout: 'ログアウト',
  selectShippingMethod: '配送方法の選択',
  standardShipping: "Standard Shipping: ${amount}",
  estimatedDeliverys: "Estimated Delivery: {date}",
  taxes: "Taxes",
  breadcrumbs: {
    classification: '分類',
    subClassification: '小分類',
    selectedProduct: '選択した商品',
  },
  downloadApp: 'アプリをダウンロード',
  register: '登録',
  verifiedPurchase: '購入確認',
  pleaseInput: '入力してください ',
  code: 'コード',
  effective: '有効 ',
  pleaseEnterPasswordAgain: 'もう一度パスワードを入力してください',
  passwordInconsistent: '2回入力したパスワードが一致しません！',
  createdSuccessfully: '作成に成功しました',
  addedSuccessfully: '追加に成功しました',
  modifyAddress: 'アドレス変更',
  modifiedSuccessfully: '変更に成功しました',
  copy: 'テキストがクリップボードにコピーされました！',
  rechargeSuccessful: 'リチャージ成功',
  deleteSuccessfully: '削除成功',
  paymentSuccessful: '支払い成功',
  payOrder: '支払い注文',
  pay: '支払い',
  available: '利用可能',
  enterPaymentPassword: '6桁の支払いパスワードを入力',
  confirmSuccessfulReceiptOfGoods: '商品受領の確認',
  correct: ' 正しい ',
  pleaseUploadInFull: '必要な画像を完全にアップロードしてください！',
  submittedSuccessfully: '正常に送信されました',
  evaluate: '評価する',
  PleaseFillInTheFormCompletely: 'フォームに必要事項を記入してください！',
  qrCodePlaceholder: 'QRコード',
  verificationCodeSentSuccessfully: '認証コードが送信されました！',
  pleaseEnterYourEmailFirst: '最初にEメールを入力してください',
  ContactTheMerchant: 'マーチャントに連絡する',
  inputIssues: '入力問題',
}
export default ja
